
/*
* 彩云天气现象转换前端可识别编码图标
*/
const skytypeByCy = [
    'CLEAR_DAY','CLEAR_NIGHT','PARTLY_CLOUDY_DAY','PARTLY_CLOUDY_NIGHT','CLOUDY',
    'LIGHT_HAZE', 'MODERATE_HAZE', 'HEAVY_HAZE',
    'LIGHT_RAIN', 'MODERATE_RAIN', 'HEAVY_RAIN', 'STORM_RAIN', 
    'FOG',
    'LIGHT_SNOW', 'MODERATE_SNOW', 'HEAVY_SNOW', 'STORM_SNOW',
    'DUST','SAND','WIND']
const skyconIcon = [
    'd00', 'd00', 'd01', 'd01', 'd02',
    'd53', 'd53', 'd53',
    'd07', 'd08', 'd09', 'd10',
    'd18',
    'd14', 'd15', 'd16', 'd17',
    'd29', 'd30', 'd20',
]
const skyconDesc = [
    // '晴（白天）','晴（夜间）','多云（白天）','多云（夜间）','阴',
    '晴','晴','多云','多云','阴',
    '轻度雾霾','中度雾霾','重度雾霾',
    '小雨', '中雨', '大雨', '暴雨',
    '雾',
    '小雪', '中雪', '大雪', '暴雪',
    '浮尘', '沙尘', '大风']

const getSkyconIndex = (skycon: string) => {

    for (let i = 0; i < skytypeByCy.length; i++) {
        const element = skytypeByCy[i]
        if(element === skycon) return i
    }
    return -1
}
const getSkyconIcon = (skycon: string) => {

    for (let i = 0; i < skytypeByCy.length; i++) {
        const element = skytypeByCy[i]
        if(element === skycon) return skyconIcon[i]
    }
    return ''
}
const getSkyconIconByIndex = (index: number) => {
    if(index>=0 && index < skyconIcon.length) {
        return skyconIcon[index]
    }
    return ''
}
const getSkyconDesc = (skycon: string) => {
    for (let i = 0; i < skytypeByCy.length; i++) {
        const element = skytypeByCy[i]
        if(element === skycon) return skyconDesc[i]
    }
    return ''
}

export default {
    getSkyconIndex, getSkyconIcon, getSkyconIconByIndex, getSkyconDesc,
}